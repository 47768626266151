// @flow
import React, { Component, createRef } from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LogoImage from './assets/neologofullpadded.png';
import ReactAudioPlayer from 'react-audio-player';
import ToneAudio from './assets/audio.wav';
import UserDetailsTable from './UserDetailsTable';
import Questionnaire from './Questionnaire';
import TinnitusMatcher from './TinnitusMatcher';
import DataPlots from './DataPlots';
import firebase from './Firebase';
import { animateScroll as scroll } from 'react-scroll';
import Loader from "react-loader-spinner";

import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';

const version = `v3.6${process.env.REACT_APP_USER === 'customer' ? 'c' : 's'}${process.env.REACT_APP_ENV === 'staging' ? '_s' : ''}`;

const Container = styled.div`
  align-items: center;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
`;

const Logo = styled.img`
  margin: 30px;
  width: 30vw;
`;

const HeaderText = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 32px;
  margin: 20px;
  text-align: center;
`;

const InstructionsText = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 20px;
  margin: 20px;
  max-width: 1200px;
  text-align: center;
`;

const LowerMargin = styled.div`
  padding: 15px;
`;

const P = styled.p`
  margin: 0.5em 0;
`;

const SubtitleText = styled.div`
  color: #2F353F;
  font-family: Roboto;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
`;

const VersionText = styled.div`
  align-self: flex-start;
  color: #2F353F;
  font-family: Roboto;
  font-size: 12px;
  margin: 5px;
  margin-top: auto;
`;

const LineBreak = styled.hr`
  width: 90%;
`;

const urlPropsQueryConfig = {
  uuid: { type: UrlQueryParamTypes.string, queryParam: 'user_uuid' }
};

window.onunload = function () {
  window.scrollTo(0, 0);
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      email: '',
      emailError: false,
      forceName: false,
      group: null,
      isAdmin: false,
      isAuthenticated: false,
      isControl: false,
      isParticipant: true,
      isPlaying: false,
      isSubmitting: false,
      latestQuestionnaireUpdate: null,
      newUserError: false,
      newUserId: null,
      newUserIsControl: false,
      newUserName: '',
      password: '',
      passwordError: false,
      users: {},
      uuid: null,
    };
    this.audioRef = createRef();
    this.usernameRef = createRef();
    this.passwordRef = createRef();

    const { uuid } = this.props;
    const isValidUuid = (uuid) => {
      const re = /[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}/
      return re.test(String(uuid).toLowerCase());
    }

    if (uuid && isValidUuid(uuid)) {
      this.state = {
        ...this.state,
        uuid,
        isAuthenticated: true,
        isControl: false,
        isParticipant: false,
      }
    }
  }

  onClose() {
    const { isPlaying } = this.state;
    if (isPlaying) {
      this.audioRef.audioEl.current.pause();
    }
  }

  onPlay = (isNowPlaying) => {
    const { uuid } = this.state;
    this.setState({ isPlaying: isNowPlaying });
    const timestamp = new Date().getTime();
    const userDataRef = firebase.database().ref(`userData/${uuid}/${isNowPlaying ? 'play' : 'pause'}`);
    userDataRef.update({ [timestamp]: true })
  }

  componentDidMount() {
    window.addEventListener('beforeunload', (event) => {
      const { isPlaying } = this.state;
      if (!isPlaying) return;
      this.onClose();
      event.preventDefault();
      return event.returnValue = 'Are you sure you want to close?';
    });
  }

  MainContent() {
    const {
      displayName,
      isControl,
      isParticipant,
      uuid,
      latestQuestionnaireUpdate
    } = this.state;
    const showNtm = process.env.REACT_APP_USER !== 'customer';
    const showPlots = true;
    const showTones = process.env.REACT_APP_USER !== 'customer';
    const onQuestionnaireSubmitted = () => {
      this.setState({ latestQuestionnaireUpdate: new Date().toISOString() });
    }

    const Instructions =
    process.env.REACT_APP_USER === 'customer' ?
    (
      <InstructionsText>
        <P>The first step to improvement is tracking. Below you'll be able to take brief tests once a week and track changes through time.</P>
      </InstructionsText>
    ) : (
      <InstructionsText>
        <P>This is a test to see if your tinnitus improves over time with our short intervention. Begin by taking the {showNtm && "tinnitus matcher and "} weekly questionnaire below.</P>
        <P>Then, <i><b>each day</b></i>, return to this site and listen to the audio below for ten minutes a day (or more, if you wish). The audio is a sequence of tones that loops continuously.</P>
        <P>Please listen through speakers, not headphones. You may adjust the volume to whatever level you find comfortable.</P>
        {!isControl &&
          <P>When listening to the audio, make sure to wear your Buzz on Everyday mode and on its highest sensitivity setting.</P>
        }
        <P>You may do other activities during the ten minutes of listening as long as you are not making noise.</P>
        {!isControl &&
          <P>You may wear Buzz outside of the ten-minute listening period if you wish.</P>
        }
        <P>Once a week, retake the {showNtm && "tinnitus matcher and "} questionnaire below.</P>
        <P>Please press the play button to begin.</P>
      </InstructionsText>
    );

    return (
      <>
        {
          process.env.REACT_APP_USER !== 'customer' &&
          <SubtitleText>
            User: { displayName }
          </SubtitleText>
        }
        { Instructions }
        {
          showTones &&
          <>
            <ReactAudioPlayer
              controls
              loop={true}
              onPause={() => this.onPlay(false)}
              onPlay={() => this.onPlay(true)}
              ref={(input) => {this.audioRef = input}}
              src={ ToneAudio }
            />
            <LowerMargin />
          </>
        }
        { showNtm && (
          <>
            <LineBreak />
            <TinnitusMatcher uuid={uuid} isParticipant={isParticipant} />
          </>
        )}
        { showPlots && (
          <DataPlots uuid={uuid} latestQuestionnaireUpdate={latestQuestionnaireUpdate} />
        )}
        <Questionnaire
          uuid={uuid}
          onQuestionnaireSubmitted={onQuestionnaireSubmitted}
          isParticipant={isParticipant}
        />
        <LowerMargin />
      </>
    );
  }

  PasswordContent() {
    const { emailError, passwordError, isSubmitting } = this.state;
    const emailInputHelperText = emailError ? 'Please enter a valid email' : '';
    const inputHelperText = passwordError ? 'Incorrect password' : '';

    const checkPasswordFirebase = async (password) => {
      const isActiveRef = firebase.database().ref(`users/${password}/isActive`);
      try {
        const isActive = await isActiveRef.once('value');
        return isActive.val();
      } catch (e) {
        return false;
      }
    }

    const apiPoint = process.env.NODE_ENV === 'development' ?
     '/api' : (
       process.env.REACT_APP_ENV === 'staging' ?
       'https://web.dev.neos-internal.com/v1' :
       'https://api.neosensory.com/v1'
      );

    const checkPasswordNeoBackend = async (email, password) => {
        const tokenResponse = await fetch(`${apiPoint}/auth/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });
        const tokenStatus = tokenResponse.status;
        if (tokenStatus === 201) {
          return tokenResponse;
        } else {
          return false;
        }
    };

    const getUserData = async (tokenResponse) => {
      const tokenData = await tokenResponse.json();
      const token = tokenData.data.token;
      const userDataResponse = await fetch(`${apiPoint}/user`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json',
          'Authorization': `bearer ${token}`,
        },
      });
      const userDataResponseJson = await userDataResponse.json();
      return userDataResponseJson.data;
    }

    const checkIsAdmin = async (uuid) => {
      const isAdminRef = firebase.database().ref(`users/${uuid}/isAdmin`);
      try {
        const isAdmin = await isAdminRef.once('value');
        return isAdmin.val();
      } catch (e) {
        return false;
      }
    }

    const checkIsControl = async (uuid) => {
      const isControlRef = firebase.database().ref(`users/${uuid}/isControl`);
      try {
        const isControl = await isControlRef.once('value');
        return isControl.val();
      } catch (e) {
        return false;
      }
    }

    const checkGroup = async (uuid) => {
      const groupRef = firebase.database().ref(`users/${uuid}/group`);
      try {
        const group = await groupRef.once('value');
        if (!group.exists()) return null;
        return group.val();
      } catch (e) {
        return false;
      }
    }

    const fetchUsers = async () => {
      const usersRef = firebase.database().ref('users');
      const usersSnapshot = await usersRef.once('value');
      if (usersSnapshot.exists()) this.setState({ users: usersSnapshot.val() });
      const userDataSnapshot = await firebase.database().ref('userData').once('value');
      if (!userDataSnapshot.exists()) return;
      Object.keys(userDataSnapshot.val()).forEach((key) => {
        const { users } = this.state;
        const updatedUsers = { ...users, [key]: { ...users[key], hasPlayed: true, data: userDataSnapshot.val()[key] }};
        this.setState({ users: updatedUsers });
      });
    }

    const onSubmit = async () => {
      const { email, password } = this.state;
      this.setState({ isSubmitting: true })
      const isValid = email ? false : await checkPasswordFirebase(password);
      if (isValid) {
        const isAdmin = await checkIsAdmin(password);
        if (isAdmin) {
          fetchUsers();
        }
        const isControl = await checkIsControl(password);
        const group = await checkGroup(password);
        this.setState({
          displayName: password,
          group,
          isAdmin,
          isAuthenticated: true,
          isControl,
          isParticipant: true,
          uuid: password,
        });
        scroll.scrollToTop();
        this.setState({ isSubmitting: false })
        return;
      }

      if (!isValidEmail(email)) {
        this.setState({ emailError: true, isSubmitting: false })
        return;
      }
      const isNeoUser = await checkPasswordNeoBackend(email, password);
      if (isNeoUser) {
        const user = await getUserData(isNeoUser);
        const { email, uuid } = user;
        this.setState({
          displayName: email,
          group: null,
          isAdmin: false,
          isAuthenticated: true,
          isControl: false,
          isParticipant: false,
          uuid,
        });
        scroll.scrollToTop();
        this.setState({ isSubmitting: false })
        return;
      }
      this.setState({ passwordError: true, isSubmitting: false })
    }

    const onChangePassword = (event) => {
      this.setState({ password: event.target.value, passwordError: false })
    }

    const onChangeEmail = (event) => {
      this.setState({ email: event.target.value, emailError: false })
    }

    const isValidEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    return (
      <>
        <InstructionsText>
          {
            process.env.REACT_APP_USER === 'customer' ?
          (
            <>
              <P>Please sign in with the same email and password you use for the Neosensory App.</P>
              <P>If you do not have a login, install the Neosensory app from <a href="https://play.google.com/store/apps/details?id=com.neosensory">Google Play</a> or the <a href="https://apps.apple.com/us/app/neosensory/id1480756997">App Store</a> and create an account through the app.</P>
            </>
          ) : (
            <P>Please sign in with your six digit user ID to access this site.</P>
          )}
        </InstructionsText>
        { process.env.REACT_APP_USER === 'customer' &&
          <TextField
            error={emailError}
            helperText={emailInputHelperText}
            id="email-input"
            inputRef={this.usernameRef}
            key="email-input"
            label="Email"
            onChange={onChangeEmail}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.passwordRef.current.focus();
                ev.preventDefault();
              }
            }}
            style={{ margin: '20px' }}
            type="email"
            variant="outlined"
          />
        }
        <TextField
          autoComplete="current-password"
          error={passwordError}
          helperText={inputHelperText}
          id="password-input"
          inputRef={this.passwordRef}
          key="password-input"
          label={process.env.REACT_APP_USER === 'customer' ? "Password" : "User ID"}
          onChange={onChangePassword}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              onSubmit();
              ev.preventDefault();
            }
          }}
          type="password"
          variant="outlined"
        />
        {
          isSubmitting ?
          (
            <Loader
              type="Oval"
              color="#3f51b5"
              height={30}
              width={30}
              style={{ margin: "20px" }}
            />
          ) : (
            <Button
              color="primary"
              onClick={onSubmit}
              size="large"
              style={{ margin: "20px" }}
              variant="contained"
            >
              Submit
            </Button>
          )
        }
      </>
    );
  }

  AdminContent() {
    const {
      forceName,
      newUserError,
      newUserIsControl,
      password,
      users,
    } = this.state;
    const inputHelperText = newUserError ? 'Please enter a name' : '';

    const onChangeNewUserName = (event) => {
      this.setState({
        newUserError: false,
        newUserId: null,
        newUserName: event.target.value,
      })
    };

    const getUniqueId = async () => {
      const newUserId = Math.floor(Math.random() * 900000) + 100000; // [100000, 999999]
      const newUserRef = firebase.database().ref(`users/${newUserId}`);
      const userSnapshot = await newUserRef.once('value');
      const userExists = userSnapshot.exists();
      return userExists ? getUniqueId() : newUserId;
    }

    const onCreateNewUser = async () => {
      const {
        forceName,
        newUserIsControl,
        newUserName,
      } = this.state;
      if (newUserName === '') {
        this.setState({ newUserError: true });
        return;
      }

      const newUserId = forceName ? newUserName : await getUniqueId();
      const newUserRef = firebase.database().ref(`users/${newUserId}`);
      const creationTime = new Date().getTime();
      const user = {
        createdBy: password,
        creationTime,
        isActive: true,
        isControl: newUserIsControl,
        username: newUserName,
      }
      await newUserRef.set(user);
      this.setState({
        newUserId,
        users: { ...users, [newUserId]: user },
      });
    };

    const handleControlSwitchChange = (event) => {
      this.setState({ newUserIsControl: !event.target.checked })
    }

    const handleForceNameSwitchChange = (event) => {
      this.setState({ forceName: event.target.checked })
    }

    return (
      <>
        <TextField
          error={newUserError}
          helperText={inputHelperText}
          id="new-user-input"
          key="new-user-input"
          label="User name"
          onChange={onChangeNewUserName}
          variant="outlined"
        />
        <FormControlLabel
          control={<Switch
            name="controlSwitch"
            color="primary"
            checked={!newUserIsControl}
            onChange={handleControlSwitchChange}
            />}
          label="New user gets a wristband"
        />
        <FormControlLabel
          control={<Switch
            name="forceNameSwitch"
            color="primary"
            checked={forceName}
            onChange={handleForceNameSwitchChange}
            />}
          label="Username is also passcode"
        />
        <Button
          color="primary"
          key="new-user-button"
          onClick={onCreateNewUser}
          size="large"
          style={{ margin: "20px" }}
          variant="contained"
        >
          Create new user
        </Button>
        { Object.keys(users).length > 0 &&
          <div>
            <div style={{ margin: 30 }} />
            <UserDetailsTable key="user-details-table" users={users}/>
            <div style={{ margin: 20 }} />
          </div>
        }
      </>
    );
  }

  render = () => {
    const { isAdmin, isAuthenticated } = this.state;
    let content = this.PasswordContent();
    if (isAuthenticated) content = this.MainContent();
    if (isAdmin) content = this.AdminContent();
    return (
      <Container>
        {
          process.env.REACT_APP_USER === 'customer' &&
          <Logo src={LogoImage} alt={'Neosensory Logo'}/>
        }
        <HeaderText>
          {
            process.env.REACT_APP_USER === 'customer' ?
            'Tinnitus Progress Tracker' :
            'Tinnitus Test'
          }
          { isAdmin ? ': Admin Portal' : '' }
        </HeaderText>
        { content }
        <VersionText>
          { version }
        </VersionText>
      </Container>
    );
  }
};

export default addUrlProps({ urlPropsQueryConfig })(App);
